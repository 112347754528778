import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";

import Img1 from "../images/airport-transfer.png";
import Img2 from "../images/corporate transfer.png";
import Img3 from "../images/vacation-trnsfer.png";
import Img4 from "../images/station-transfer.png";

const ThreeColWithImageHover = ({ Data = null, SectionTitle }) => {
  const defaultData = [
    {
      title: "Airport Transfer",
      description: "Guranteed service",
      img: Img1,
      url: {},
    },
    {
      title: " Corporate Transfer",
      description: "Guranteed service",
      img: Img2,
      url: {},
    },
    {
      title: "Vacation Transfer ",
      description: "Guranteed service",
      img: Img3,
      url: {},
    },
    {
      title: "Station Transfer ",
      description: "Guranteed service",
      img: Img3,
      url: {},
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper pt-0 ">
      <Container fluid>
        <div className="text-center ">
          {SectionTitle && <h2 className="small__title ">{SectionTitle}</h2>}
          {SectionTitle && (
            <h2 className="section__title w-50 ">
              Affordable Boston Logan Airport Taxi Service
            </h2>
          )}
        </div>
        <div className="text-center">
          <Row>
            {Data.map((info, i) => (
              <Col className="threecolwithimagehover__unit" lg="3" key={i}>
                <img src={info.img} alt={info.title || ""} />
                <div className="service_desc">
                  <h4>{info.title || ""}</h4>
                  <p className="description">{info.description || ""}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImageHover;
