import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import review from "../images/reviweer.png";
import reviewQuote from "../images/review_quote.png";

const ReviewSlider = ({ Info = null, SectionTitle }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplaySpeed: 2500,
    speed: 2500,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const defaultInfo = [
    {
      icon: review,
      title: "Hannah Schmitt",
      designation: "CEO, ABC.Co",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus ",
    },
    {
      icon: review,
      title: "Mr. Man",
      designation: "Manager Travel",
      description:
        "Labore et dolore magna aliqua. Ut enim corehenderit in cupidatat non proident, sun. ",
    },
    {
      icon: review,
      title: "John Doe",
      designation: "Manager Indoa",
      description:
        "Serspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    },
  ];

  if (!Info) Info = defaultInfo;
  return (
    <>
      <div className="section__gap homepage-review  pb-5 pt-0">
        <Container fluid>
          <Row>
            <Col lg="7">
              <Slider {...settings}>
                {Info.map((info, i) => (
                  <div className="reviewitem" key={i}>
                    <small>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </small>
                    <p className="text-left">{info.description}</p>
                    <div className="reviewer-info">
                      <img src={info.icon} alt="" />
                      <h5>{info.title}<br/> 
                      <span>{info.designation}</span>
                      </h5>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
            <Col lg="5">
              <img src={reviewQuote} alt="" />
              <h2 className="section__title text-left">
                What Customer Say <br />
                About <span> Us </span>
              </h2>
              <p className="text-left">
                We like to build close relationships with our clients. We
                believe dynamic collaboration is the only way to get the job
                done.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ReviewSlider;
