import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

const Terms = () => {
  return (
    <>
      <Nav
        title="Terms & Conditions  - Boston Airport Express Car"
        desc="Terms and conditions for taxi service to Logan airport. Easy terms for taxi cab Boston Logan airport. Contact Boston Airport Express Car to knowm ore information today."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Terms & Conditions
              </h2>
              <h4 class="mb-2  md__title ">
                Find here our terms and conditions for a town car and taxi cab
                services for Boston Logan airport
              </h4>
              <p>
                We accept all types of Credit Cards & PayPal. Boston Airport
                Express Car (DBA) is Incorporated by Logan Airport Car, Inc.
              </p>
              <p>
                LOGAN AIRPORT CAR, INC" will appear on the Credit Card / PayPal
                Billing Statement
              </p>
              <h4 class="mb-2 mt-4  md__title ">Service Hours</h4>
              <p>
                We provide 24/7 Airport Transportation - except the state of
                Emergency
              </p>
              <h4 class="mb-2 mt-4  md__title "> Payment Options</h4>
              <p>
                PayPal or Credit Card. An option of payment must be required to
                complete online booking. All PayPal payment goes to under
                Corporate email to :(loganairportcarservice@gmail.com).
              </p>
              <h4 class="mb-2 mt-4  md__title ">Luggage Restriction</h4>
              <p>
                There are maximum luggage restrictions depending on the vehicle
                Capacity. Please check your vehicle luggage capacity from the
                fleet/Fare Quote page. Any additional Luggage may cost each
                $3.00 – Please Specify the Luggage amount while booking.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Change</h4>
              <p>
                Change/Modify/Cancellation is free of cost before 4 (Four) hours
                of actually scheduled pickup. Please simply email
                info@bostonairportexpresscar.com for the change or Cancel
                Request.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Refund</h4>
              <p>
                Any refund which is Paid by PayPal/Credit Card will be processed
                within 24 (Twenty Four) hours.
              </p>
              <h4 class="mb-2 mt-4  md__title ">No-Show</h4>
              <p>
                In case of passenger no-show. A no-show (full fare) will
                occurred, no refund will be allowed. Passenger must contact us –
                or- email for reschedule/change/cancel an existing reservation 4
                (Four) hours before scheduled pickup.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Cancellation</h4>
              <p>
                Cancellation before 4 (Four) hours - will be refunded in full.
                Less than 4 (Four) hours a refund will not be allowed.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Reservation</h4>
              <p>
                (DAY) From 8:00 A.M - 8:00 P.M. Online Reservation Required at
                least 4 (Four) hours advance to schedule a Car. (NIGHT) From
                8:00 PM - 8:00 AM required minimum 8 (Eight) hours for online
                reservation. Need Urgent Booking, Please Call us (617) 499-7770.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Tolls & Fees</h4>
              <p>
                All Tolls & Charges are Included in fare as per Airport
                Authority Rules
              </p>
              <h4 class="mb-2 mt-4  md__title ">State Emergency</h4>
              <p>
                In case of State Emergency or snow Storms or heavy snowfall, we
                might have to cancel the service, we will contact passenger
                before cancellation. Canceled for Natural Causes, Change/Refund
                can be requested/Issued.
              </p>
              <h4 class="mb-2 mt-4  md__title ">
                To Airport-Pickup Waiting Time:
              </h4>
              <p>
                All Pick-up to the Airport- Driver will wait up to 15 (fifteen)
                minutes. Please contact driver, if you need more time. Please
                contact driver, if you need more time or No-Show may occurred.
              </p>
              <h4 class="mb-2 mt-4  md__title ">From Airport Pick-Up</h4>
              <p>
                All Pickup-from the airport- Driver will wait up to 1 hours from
                the actual flight arrival/landing time. If you need more than 1
                (ONE) hour - Please contact driver, if you need more time or
                No-Show may occurred.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Waiting charge</h4>
              <p>
                Waiting charge is $1.00 per minute after Maximum waiting
                Minutes.
              </p>
              <h4 class="small__title ">
                HAVE ANY QUESTIONS OR CONCERNS? PLEASE CALL: (978) 268-0700
              </h4>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Terms;
