import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftIconMedia from "./LeftIconMedia";

import aboutImg from "../images/about.png";
import dollor from "../images/dollar-circle.png";
import purse from "../images/wallet-check.png";
import money from "../images/moneysicon.png";

const TwoColWithImage = ({ Text = null }) => {
  const defaultText = [
    {
      smtitle: "Logan airport town car and yellow cab services",
      title: "Boston Logan Taxi Services and Yellow Cab Services",
    },
  ];

  if (!Text) Text = defaultText;
  return (
    <>
      <section className="section__gap feature__section">
        <Container fluid>
          <Row>
            <Col xs lg="7">
              {Text.map((info, i) => (
                <div key={i}>
                  <h4 class="small__title text-left">{info.smtitle || ""}</h4>
                  <h2 class="section__title text-left">{info.title || ""}</h2>
                </div>
              ))}
              <LeftIconMedia
                icon={dollor}
                title="Best Boston Airport Taxi Services"
                description="Most people regularly travel by hiring our Boston airport taxi service for their business and tour needs."
              />
              <LeftIconMedia
                icon={purse}
                title="Book your Boston Airport Car Service"
                description="Boston Airport Express Car has a large fleet of sedans, Minivans, and SUVs in its fleet and customers can always select the most suitable car as per their requirement and budget."
              />
              <LeftIconMedia
                icon={money}
                title="Best Logan Airport Taxi Service"
                description="Are you searching for Logan airport taxi service or Boston Logan airport taxi service? Then don’t waste your time to find airport taxi service in Logan or Boston."
              />
              <LeftIconMedia
                icon={money}
                title="Affordable Boston Logan Airport Taxi Service                "
                description="Do you reside in Boston or Logan and need to pick up a flight on time from Boston or Logan? If yes, then there is no better alternative than Boston Airport Express Car, which provides Boston"
              />
            </Col>
            <Col xs lg="5">
              <img className="img-fluid" src={aboutImg} alt="image" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default TwoColWithImage;
