import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import logo from "../images/logo.png";

const FooterComp = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <Container fluid>
         
          <Row>
            <Col lg="3">
              <img src={logo} />
              <p>
              Boston Airport Express Car Service Provides Logan Airport Car Service, Boston Airport Taxi Service, and premium transportation service for travelers coming in and out of the Massachusetts and New England areas
               </p>
              
            </Col>
            <Col lg="2">
              <h4>Navigation</h4>
              <ListGroup>
                <ListGroup.Item>
                  <a href="/about-us">About</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-airport-car-service">Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/fleet">Fleet</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/contact-us-for-hire-taxi">Contact</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/reserve-a-ride">Reserve a Ride</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/privacy-policy">Privacy policy</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
           
            <Col lg="4">
              <h4>Location We Serve</h4>
              <ListGroup className="list__count__2">
                <ListGroup.Item>
                  <a href="/woburn-to-boston-logan-airport-taxi">Woburn</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/lexington-to-boston-logan-taxi">Lexington </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/newton-to-boston-logan-taxi">Newton</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/brookline-village-to-boston-logan-taxi"> Brookline Village </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/arlington-to-boston-logan-taxi">Arlington </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-to-boston-logan-taxi">Boston </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/reading-to-boston-logan-taxi">Reading </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/lowell-to-boston-logan-taxi">Lowell </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/bedford-to-boston-logan-taxi">Bedford</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/cambridge-to-boston-logan-taxi">Cambridge </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/natick-to-boston-logan-taxi">Natick </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/marlborough-to-boston-logan-taxi">Marlborough </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/burlington-to-boston-logan-taxi">Burlington  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/waltham-to-boston-logan-taxi">Waltham  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/revere-to-boston-logan-taxi">Revere  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/concord-to-boston-logan-taxi">Concord   </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="3">
              <h4>Contact Us</h4>
              <p>
                <strong>Office: </strong>
                Concord Airport Taxi & Car Services 300 Baker Avenue, Concord,
                MA 01742
              </p>
              <p>
                <strong>Phone: </strong>
                <a href="tel:(617) 499-7770">(617) 499-7770</a>
              </p>
              <p>
                <strong>Email: </strong>
                <a href="mailto:info@bostonairportexpresscar.com">
                  info@bostonairportexpresscar.com
                </a>
              </p>
              <div className="social-icons">
              <a href="#!">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a href="#!">
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a href="#!">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </div>
            </Col>
          </Row>
          <hr />
          <div className="copyright">
            <p>
              Copyright {year} All Rights Reserved. Boston Airport Express Car.
            </p>
          
          </div>
        </Container>
      </footer>
    </>
  );
};
export default FooterComp;
