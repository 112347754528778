import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";
import Button from "react-bootstrap/Button";

import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png";
import Img4 from "../images/4pax.png";

const About = ({ Data = null }) => {
  const defaultData = [
    {
      title: "COMPACT SEDAN",
      description: "Compact Sedan for up-to 2-Passenger with 2 carry on Luggages | Economic & Spacious car.",
      img: Img1,
    },
    {
      title: "3-PAX SEDAN",
      description: "3-Pax Sedan for up-to 3-Passenger with Maximum 3/4 (THREE/FOUR) Luggage | Safe & Comfortable.",
      img: Img2,
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "6/7 Passengers Minivan or up-to 6/7-Passengers with Maximum of 6 (SIX) Luggages | Best Car for family with child. Very Comfortable for Long Distance Ride. All type of child/infant seat available upon request.",
      img: Img3,
    },
    {
      title: "4-PAX-MINIVAN",
      description: "  4-Pax-Minian for up-to 4-Passenger with larger amount of luggages | Maximum 10 (TEN) Luggages can fit in the Cargo-Van. | Best Car for family with child/infant with multiple Luggage. Very Comfortable Journey for Long Distance Rides. All type of child/infant seat available upon request.",
      img: Img4,
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <>
      <Nav
        title="Our Fleets - Boston Airport Express Car"
        desc="Our fleet for Boston Logan taxi service. We have best services and cheap Logan airport taxi rates, visit the website to review our fleets and contact Boston Airport Express Car if you have any questions."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap fleet-page">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            Taxis Fleet
          </h2>
          <p>
            With the immaculate wide range of vehicles, we can accommodate your
            transportation request. Whether it's a small or group transfer from
            Acton MA to Boston International Airport or Acton MA to Logan
            Airport to anywhere you can always rely on us.
          </p>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   pb-5">
                <strong> Book a Taxi in Boston</strong>
              </h4>
            </Col>
          </Row>
          <Row>
        {Data.map((info, i) => (
          <Col className="threecolwithimage__unit" xs lg="6" key={i}>
            <img src={info.img} alt={info.title || ""} />
            <h4>{info.title || ""}</h4>
            <p className="description">{info.description || ""}</p>
            <div className="price-box">
              <h5>{info.price}</h5>
            </div>
          </Col>
        ))}
      </Row>
        </Container>
      </div>
      
      <FooterComp />
    </>
  );
};
export default About;
