import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";
const AreaWeServe = () => {
  return (
    <>
      <Nav
        title="Taxi service Boston | Boston Airport Car | Boston Taxi-Shuttle"
        desc="Boston Airport Express Car Service is provided through several territories that offers driven Car service Boston, taxi service Boston, taxi with car infant car seat and Boston car service with child seat"
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
          AREA WE SERVE
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   md__title">
                <strong> Areas we serve | Boston airport car service | Boston airport taxi-shuttle</strong>
              </h4>
              <p>Boston Airport Express Car proudly serves a wide range of locations surrounding the Boston and New England area <strong>with car service Boston, Taxi service Boston, Boston Airport car with car seat, Boston Airport taxi-shuttle and premier taxi service, a taxi with infant car seat and Boston car service with child seat and minivans.</strong></p>
               
              <p>
              We strive to provide each one of our customers with relaxing, friendly, and enjoyable transportation regardless of their destination. Travelling can be stressful, but with our Logan Airport Car Service and taxi services, it’s easy to find and book a professional driver for your stay.
              </p>
              <p>
              We guarantee punctuality, safety, and reliability when you choose our services in Boston Logan or any other area we serve.
              </p>
              <p>Our drivers will make sure that you are never late to Airport, an important business meeting or to an important family event. Fast, efficient, and friendly service is at the core of everything we do.</p>
              <p>In addition to prompt and professional drivers, we also offer spacious and clean vehicles and Logan Airport Shuttle for your transportation needs.</p>
              <p>Whether you need door-to-door Logan Airport taxi service or simply require airport drop off and pick-up, our services fit the bill. Need to know if we can take you where you need to go? Feel free to browse through the full list of areas we serve down below.</p>
              <h4 className="mb-4  small__title ">GUARANTEED SERVICE -  BOOK ONLINE OR CALL US TODAY!</h4>
            </Col>
          </Row>
          <Row>
            <Col>
            <h4 className="mb-4   md__title">
              <strong> Area We Serve</strong>
              </h4>
              <ListGroup className="box-list-inline"> 
                <ListGroup.Item>
                  <a href="/acton">Acton</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston">Boston</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/framingham">Framingham</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/hudson">Hudson</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/marlborough">Marlborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/maynard">Maynard</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/natick">Natick</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/northborough">Northborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/sudbury">Sudbury</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/wayland">Wayland</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/westborough">Westborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/weston">Weston</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};
export default AreaWeServe;
