import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import logo from "../images/logo.png";
import menubtn from "../images/menubtn.png";

const HeaderComp = ({ title, desc }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const openNav = () => {
    document.getElementById("sidenav").style.left = "0px";
  };
  const closeThisNav = () => {
    document.getElementById("sidenav").style.left = "-250px";
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <Navbar className="Nav__wrapper">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="m-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                href="/"
                className={splitLocation[1] === "" ? "active" : ""}
              >
                Home
              </Nav.Link>

              <Nav.Link
                href="/about-us"
                className={splitLocation[1] === "about-us" ? "active" : ""}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="/logan-airport-to-areas-we-serve"
                className={
                  splitLocation[1] === "logan-airport-to-areas-we-serve"
                    ? "active"
                    : ""
                }
              >
                Area We Serve
              </Nav.Link>
              <Nav.Link
                href="/logan-airport-taxi-pickup-location"
                className={
                  splitLocation[1] === "logan-airport-taxi-pickup-location"
                    ? "active"
                    : ""
                }
              >
                Pickup Location
              </Nav.Link>
              <Nav.Link
                href="/fleet"
                className={splitLocation[1] === "fleet" ? "active" : ""}
              >
                Fleet
              </Nav.Link>
              <Nav.Link
                href="/flat-rate-to-boston-logan-airport"
                className={
                  splitLocation[1] === "flat-rate-to-boston-logan-airport"
                    ? "active"
                    : ""
                }
              >
                Flat Rate
              </Nav.Link>
              <Nav.Link
                href="/contact-us-for-hire-taxi"
                className={
                  splitLocation[1] === "contact-us-for-hire-taxi"
                    ? "active"
                    : ""
                }
              >
                Contact Us
              </Nav.Link>
            </Nav>

            <Button className="btn__main">Login</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <section className="mob__nav">
        <a href="/">
          <img src={logo} />
        </a>

        <span className="open__nav" onClick={openNav}>
          <img src={menubtn} />
        </span>
      </section>

      <div id="sidenav" className="sidenav">
        <span className="close__nav" onClick={closeThisNav}>
          <i className="fa-solid fa-times"></i> Close
        </span>
        <a href="/" className={splitLocation[1] === "" ? "active" : ""}>
          Home
        </a>

        <a
          href="/about-us"
          className={splitLocation[1] === "about-us" ? "active" : ""}
        >
          About Us
        </a>
        <a
          href="/logan-airport-to-areas-we-serve"
          className={
            splitLocation[1] === "logan-airport-to-areas-we-serve"
              ? "active"
              : ""
          }
        >
          Area We Serve
        </a>
        <a
          href="/logan-airport-taxi-pickup-location"
          className={
            splitLocation[1] === "logan-airport-taxi-pickup-location"
              ? "active"
              : ""
          }
        >
          Pickup Location
        </a>
        <a
          href="/fleet"
          className={splitLocation[1] === "fleet" ? "active" : ""}
        >
          Fleet
        </a>
        <a
          href="/flat-rate-to-boston-logan-airport"
          className={
            splitLocation[1] === "flat-rate-to-boston-logan-airport"
              ? "active"
              : ""
          }
        >
          Flat Rate
        </a>
        <a
          href="/contact-us-for-hire-taxi"
          className={
            splitLocation[1] === "contact-us-for-hire-taxi" ? "active" : ""
          }
        >
          Contact Us
        </a>

        <a href="">Login</a>
      </div>
    </>
  );
};

export default HeaderComp;
