import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png";
import paxIcon from "../images/pax-icon.png";
import lugIcon from "../images/lug-icon.png";
const ThreeColWithImage = ({ Data = null, SectionTitle }) => {
  const defaultData = [
    {
      title: "4-Passenger SUV",
      description:
        "Compact Sedan for up-to 2-Passenger with 2 carry on Luggages | Economic & Spacious car.",
      img: Img1,
      rating: "5.0",
      price: "$250",
      pax: "4",
      lug: "4",
      url: "/our-fleets",
    },
    {
      title: "10-Passenger VAN",
      description:
        "Go 10- Passenger Van | Capacity :Maximum 10 passenger & 10 Bags",
      img: Img2,
      rating: "5.0",
      price: "$460",
      pax: "10",
      lug: "10",
      url: "/our-fleets",
    },
    {
      title: "6-PAX MINIVAN",
      description:
        "Go 4 - Passenger Minivan | Maximum 10 Bags Capacity Premium Class Vehicle",
      img: Img3,
      rating: "4.5",
      price: "$670",
      pax: "4",
      lug: "10",
      url: "/our-fleets",
    },
    {
      title: "6-PAX MINIVAN",
      description:
        "Go 4 - Passenger Minivan | Maximum 10 Bags Capacity Premium Class Vehicle",
      img: Img3,
      rating: "4.5",
      price: "$670",
      pax: "4",
      lug: "10",
      url: "/our-fleets",
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper ">
      <Container fluid>
        <div className="text-center">
 
          <h2 className="section__title">
            Explore Our top Deal From <br></br> Top- rated Fleets
          </h2>
        </div>
        <div className="text-center">
          <Row>
            {Data.map((info, i) => (
              <Col className="threecolwithimage__unit" lg="3" key={i}>
                <div>
                  <img src={info.img} alt={info.title || ""} />
                  <h4>{info.title || ""}</h4>
                  <div className="fleet_stat">
                    <span>
                      <img src={paxIcon} />
                      {info.pax}
                    </span>
                    <span>
                      <img src={lugIcon} /> {info.lug}
                    </span>
                  </div>
                  <p className="description">{info.description || ""}</p>

                  <div className="price-box">
                    <a href="/fleets" className="btn__main">
                      View More
                    </a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImage;
