import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";
const Service = () => {
  return (
    <>
      <Nav
        title="Boston Airport Car, Boston Logan Airport Car Service"
        desc="Looking for best Boston Airport Car and Boston Logan Airport Car Service? We have airport cars, taxi cab, yellow cab, town car, SUV, minivan, shuttle and all type of taxi services. Renowned for Boston Logan Airport Car Services, call us today."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            Boston Logan Taxi Services And Logan Airport Town Car And Yellow Cab
            Services
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   md__title">
                <strong>
                  Areas we serve | Boston airport car service | Boston airport
                  taxi-shuttle
                </strong>
              </h4>
              <p>
                We have best, cheap and hassle-free Boston Logan taxi services
                and Logan airport town car and yellow cab services. Traveling is
                stressful, but when you book one of our fleet - from Boston
                Airport Express car, it gets in on time, worries free and safe
                comport airport transfer. As a professional and experienced
                transportation company, we do our part to make sure all of your
                needs are met. Our Boston airport taxi, Logan Airport Car
                Service, Logan Airport Shuttle, Logan Best Car Service, Limo
                Service will arrive at the airport as soon as your plane lands
                and our driver will make sure you arrive safely to your
                destination on time. We have designed our Logan car service
                around the needs of our clients, and take into consideration
                every detail, so you do not have to.
              </p>

              <p>
                Serving both Boston and Logan as well as the surrounding areas,
                our transport services encompass a broad range of destinations.
                Regardless of the reason for your stay, you can count on us to
                make sure you always arrive on-time. We guarantee that when you
                choose us you will never be late to an important business
                meeting or event.
              </p>

              <h4 className="mb-4  md__title ">Business Transportation</h4>
              <p>
                Premium, Boston Airport Car Service, Executive Logan Airport Car
                Service, Logan Airport Crew Shuttle Service, Boston Airport Taxi
                Service, Premium Car Service, Boston Airport Minivan Service
              </p>
              <h4 className="mb-4  md__title ">Leisure Transportation</h4>
              <p>
                If you are traveling to Boston or the surrounding areas for
                leisure, you can expect the following services: Boston Airport
                Minivan, Hourly Car or Minivan service, Clean and Comfortable
                Vehicles, Child, Safety Seats Upon Request, Extra Luggage Space.
              </p>
              <h4 className="mb-4  small__title ">
                GUARANTEED SERVICE - BOOK ONLINE OR CALL US TODAY!
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="mb-4   md__title">
                <strong>BOSTON AIRPORT EXPRESS CARS: ADVANTAGES</strong>
              </h4>

              <h4 className="mb-4  small__title ">
                Best Boston Logan International airport taxi cab services{" "}
              </h4>
              <ListGroup className="diamond-list">
                <ListGroup.Item>BEST RATE IN THE CITY</ListGroup.Item>
                <ListGroup.Item>SMART FARE CALCULATOR</ListGroup.Item>
                <ListGroup.Item>SPACIOUS MINI VANS</ListGroup.Item>
                <ListGroup.Item>CHILD SEATS</ListGroup.Item>
                <ListGroup.Item>EXTRA LUGGAGE</ListGroup.Item>
                <ListGroup.Item>SECURE PAYMENTS</ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};
export default Service;
