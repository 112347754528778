import React, { Component } from "react";
import Quote from "../components/QuoteComp";
import Button from "react-bootstrap/Button";
import Banner from "../images/banner.png";

const MainBannerComp = () => {
  return (
    <div className="hp__banner">
      <img src={Banner} alt="banner" />
      <Quote />
      <div className="banner__text">
        <span>
        A reliable taxi cab & town car services
        </span>
        <h1>
        Boston Airport Express Cars- Logan airport taxi service
        </h1>
        <p>
        Boston Airport Express Car Service Provides Logan Airport Car Service, Boston Airport Taxi Service, and premium transportation service for travelers coming in and out of the Massachusetts and New England areas. Our Logan Airport Taxi services are designed to make sure you arrive on time to your destination safely and in comfort.




        </p>
        
      </div>
    </div>
  );
};

export default MainBannerComp;
