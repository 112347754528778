import React from "react";

import Nav from "../components/NavComp";
import Banner from "../components/MainBannerComp";
import TwoColWithImage from "../components/TwoColWithImage";
import ThreeColWithImage from "../components/ThreeColWithImage";
import TwoColAppSection from "../components/TwoColAppSection";
import ReviewSlider from "../components/ReviewSlider";
import FooterComp from "../components/FooterComp";
import ThreeColWithImageHover from "../components/ThreeColWithImageHover";
const Index = () => {
  return (
    <>
      <Nav
        title="Boston Airport Taxi | Logan Airport Car | Boston Airport Express Car"
        desc="Boston Airport Express Car provides Boston Airport taxi service and Boston Logan airport cab service with competitive fares that make the passengers journey adorable. Our Boston Logan Airport Cab services and Boston Airport Car service are designed to make sure you arrive on time to your destination safely and in comfort."
      />
      <Banner />
      <ThreeColWithImage  />
      <TwoColWithImage />
      <ThreeColWithImageHover SectionTitle="Services" />
   
      <ReviewSlider SectionTitle="What Our Clients are saying About us" />
      <TwoColAppSection />
      <FooterComp />
    </>
  );
};

export default Index;
