import logo from "./logo.svg";
import "./App.css";
import "./Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./pages/Index";
import About from "./pages/About";
import AreaWeServe from "./pages/AreaWeServe";
import Fleet from "./pages/Fleet";
import Contact from "./pages/Contact";
import ServiceArea from "./pages/ServiceArea";
import Pickup from "./pages/PickupLocation";
import ServiceDetails from "./pages/ServiceDetails";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import FlatRate from "./pages/FlatRate";
import Service from "./pages/Service";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about-us">
          <About />
        </Route>
        <Route path="/boston-airport-car-service">
          <Service />
        </Route>
        <Route path="/logan-airport-to-areas-we-serve">
          <AreaWeServe />
        </Route>
        <Route path="/logan-airport-taxi-pickup-location">
          <Pickup />
        </Route>
        <Route path="/flat-rate-to-boston-logan-airport">
          <FlatRate />
        </Route>
        <Route path="/fleet">
          <Fleet />
        </Route>
        <Route path="/contact-us-for-hire-taxi">
          <Contact />
        </Route>
        <Route path="/woburn-to-boston-logan-airport-taxi">
          <ServiceDetails
            metatitle="Boston Logan International airport to Woburn ma taxi services"
            metadesc="We provide best rates for taxi services from Woburn ma to Logan airport. Inexpensive Woburn to Logan taxi and Logan to Woburn taxi."
            title="Boston Logan International Airport To Woburn Ma Taxi Services With Affordable Fares"
            title1="Woburn MA, United States"
            title2="Boston Logan International airport to/from Woburn ma taxi services with affordable fares"
            desc2="Need a ride to Boston Logan Airport? Our taxi service for Woburn ma and Woburn to Logan demands best regarding price and service in the city. Boston Airport Express Car Service offers Logan Airport Car Service, Boston Airport Car Service, Woburn Car Service, Transportation from/to Woburn to Logan International Airport. We are the Boston most experienced Airport Transportation company that strives to exceed your expectations."
            desc3="Logan Airport Car Service is not a taxi company but we arrange airport transportation included Logan Airport taxi. We raise the standard of transportation and provide our customers with luxury vehicles and unbeatable customer service. We always remain customer focused and do whatever possible to deliver a superior service that you can trust to get you to the check-in counter on time."
            desc4="Whether you’re traveling to the airport for business or a family holiday, our fleet of Logan Airport taxis and Woburn Car Service which comes fully insured and all cars are sure to service your every need. We offer our customers multiple transport options and added features such as extra luggage space and child seats where required."
            desc5="Your safety and comfort is our priority and all our fleet chauffeurs are experienced professionals. Logan Airport Car Service pride themselves on being the best-in-class airport transportation company, offering an efficient and reliable service for the best price in Auburn. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation."
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY!"
          />
        </Route>
        <Route path="/lexington-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Lexington MA Airport Taxi Service, Lexington MA to Logan Airport taxi "
            metadesc="Looking for Lexington MA Airport Taxi Service? We provide taxi cab services from Lexington ma to Boston Logan airport and from Boston Logan airport to Lexington ma. For Lexington MA Airport Taxi Service, call us at (617)499-7770."
            title="Logan Airport To Lexington Ma Taxi Cab Services – Boston Airport Express Car"
            title1="Lexington MA, United States "
            title2="Call (617) 499-7770 for Logan airport taxi cab and town car services to and from Lexington, MA "
            desc2="We go the extra mile for our customers and our large range of vehicles is sure to cater to all your transportation needs. We offer our customers no-cost extras such as extra luggage space, child seats and an efficient shuttle service running to and from the airport. Unlike some companies, we don’t overcharge for delivering this premium service. Our rates are some of the most competitive around which is why we remain our customer’s first choice when traveling to Logan Airport. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation. "
            desc3="Logan Airport Car Service is not a taxi company but we arrange airport transportation included Logan Airport taxi. We raise the standard of transportation and provide our customers with luxury vehicles and unbeatable customer service. We always remain customer focused and do whatever possible to deliver a superior service that you can trust to get you to the check-in counter on time. "
            desc4=" "
            desc5=" "
            boldtitle1=" Look our Fleet Service: Boston Airport taxi, Logan Airport Taxi, Boston Taxi Service, Lexington Car Service."
            boldtitle2="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY!"
          />
        </Route>
        <Route path="/newton-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Taxi cab from Newton to Logan airport | Boston Logan taxi rates"
            metadesc="Our taxi cab from Newton to Logan airport has best Boston Logan taxi rates. A trusted Logan car rental company."
            title="Taxi Cab From Newton To Logan Airport | Logan Car Rental | Boston Logan Taxi Rates"
            title1="Newton MA, United States "
            title2="Taxi cab from Newton to Logan airport | Get best Boston Logan taxi rates from our Logan car rental company "
            desc2="Our taxi cab from Newton to Logan airport has best Boston Logan taxi rates. Boston airport express car is a trusted Logan car rental company for your Newton to Logan ride, Newton to other cities and Logan to Newton ride with cheap fares and best services. "
            desc3="If you’re looking to travel to Logan Airport from Newton, give the friendly team at Logan Airport Express Car a call for a professional and reliable service. We are proud to be Newton’s best airport transportation service. We deliver an outstanding and punctual service that is offered at the best price, guaranteed! After you have traveled with us, you’ll understand why we are our customer’s first choice when they rely on someone to get them to their destination on time and on budget. "
            desc4="Whether you’re traveling with friends and family or a business partner, our range of taxis and corporate cars are sure to satisfy all your requirements. We want to make your journey as comfortable as possible and we offer complimentary extras such as additional luggage space, child seats, and an efficient shuttle service. Your safety and well-being is our priority and all our chauffeurs are experienced drivers with many years in the transit profession. At Logan Express Car, we pride ourselves on delivering exceptional customer service at prices that are guaranteed to be the best in Newton. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation. "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/brookline-village-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Boston Logan airport to Brookline village taxi service with cheap fare "
            metadesc="Our Boston Logan airport to Brookline village and Lowell ma taxi service have cheap fares and best service in Brookline village. "
            title="Boston Logan Airport To Brookline Village Taxi Services With Cheap Fares "
            title1="Brookline Village MA, United States "
            title2="Boston Logan airport to Brookline village and Lowell ma taxi service - cheap fares "
            desc2="Boston Airport Express Car is delighted to serve the residents of Brookline with a professional, customer-driven transportation service and Boston Logan Airport Taxi Service. We have unbeatable Boston Logan airport to Brookline village and Lowell ma taxi service regarding fares, punctuality, and services. We offer reliable transport between Brookline and Boston Logan Airport, all at the best prices guaranteed for such a high-quality service. We are positive our range of Boston Logan Airport taxis and corporate cars will cater to all your requirements, ensuring that your ride with us is stress-free and enjoyable. Our shuttle service to Logan Airport provides a convenient and comfortable means of transportation to and from the airport. No matter whether your flight is in the morning, evening, middle of the night or rush hour, trust in us to get you to the airport punctually.   "
            desc3="After you have traveled with us, you’ll understand why we are Boston’s number one choice when it comes to being picked-up or dropped-off at Logan Airport. We always provide our customers with exceptional value through a cost-effective service that gets you to your destination promptly, safely while ensuring a relaxing journey. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation. "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/arlington-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Arlington MA Airport Taxi Service, Arlington MA To Logan Airport Taxi "
            metadesc="Looking for Arlington MA Airport Taxi Service or Arlington MA To Logan Airport Taxi? We offer taxi cab, yellow cab, SUV, VAN and Minivan from Arlington ma to Boston Logan airport. For Arlington MA Airport Taxi Service, call now (617)499-7770. "
            title="Arlington Ma To Logan Airport Taxi Cab, Yellow Cab, SUV And Town Car Services "
            title1="Arlington MA, United States "
            title2="Arlington ma to Logan airport taxi cab, yellow cab, SUV and town car services. "
            desc2="BOSTON - ARLINGTON AIRPORT EXPRESS CAR AND TAXI: We are proud to serve Town of Arlington with an efficient and customer focused transportation service with Arlington Taxi to Airport, Logan Airport Car Service, Boston Car service to Logan, car service Boston, Car service Arlington, Express Arlington and Arlington express. We have taxi cab, yellow cab, town car, SUV, Minivan, VAN and shuttle services from Arlington ma to Logan airport and Boston Logan airport to Arlington ma. "
            desc3="If you are looking a ride to Logan Airport from Arlington, then contact Boston Airport Express Car for a reliable and professional service. Our range of taxis, corporate cars, minivans and SUV’s ensure that your ride with us is hassle-free and enjoyable. "
            desc4="Our shuttle service to Logan Airport provides a convenient and comfortable means of transportation to and from the airport. Trust in a punctual service that gets you to the departure lounge on time, every time, guaranteed! "
            desc5="We pride ourselves on being the best pick-up and drop-off service between Arlington and Logan Airport. Our aim is to provide exceptional value to our customers by offering a cost-effective service that gets you to your destination promptly, safely while ensuring a relaxing journey. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation. "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/boston-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Logan Airport  taxi cab to Boston | Boston Logan taxi flat rate "
            metadesc="Get Boston Logan taxi flat rate and ride with Boston cab service. Boston Logan airport car rental center. "
            title="Logan Airport Taxi Cab To Boston | Boston Logan Taxi Flat Rate | Boston Cab "
            title1="Boston MA, United States "
            title2="Get Boston Logan taxi cab flat rate for your ride from Boston to Logan airport "
            desc2="We have best taxi cab, town car, and SUV services for Boston transportation and Logan airport taxi services. You will find our price is cheaper than any other Boston Logan airport car rental center in accordance with quality service. Find our Boston Logan taxi flat rate and we guarantee to provide you best service with cheap price. We are offering the largest fleet of Boston Airport Car Service and taxis, car hire, minivans, midsize SUV’s, Full-size SUV’s service in Boston its neighborhoods city, designed to meet all the needs of our customers. Our range of Boston Airport taxis, corporate Airport car Service, Family minivans and Business Executive SUVs ensure that your ride with us is hassle-free and enjoyable. We also have a large number of Toyota Minivans, Highlander for our customers who need extra space. In addition, we continue to add Prius hybrid vehicles to our fleet in an effort to reduce our carbon footprint and do our part to help the environment. Our commitment to providing you comfortable transportation to and from the airport. Trust in a punctual service that gets you to the departure lounge on time, every time, guaranteed! "
            desc3="We pride ourselves on being the best pick-up and drop-off service between Boston and Logan Airport. Our aim is to provide exceptional value to our customers by offering a cost-effective and flat rates car service, Logan Airport taxi that gets you to your destination promptly, safely while ensuring a relaxing journey. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your online reservation. "
            desc4=" "
            desc5=" "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/reading-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Taxi and cab services for Reading and North Reading ma "
            metadesc="Get Logan airport taxi flat rate for taxi and cab services from Logan airport to Reading and North Reading ma and Stoneham taxi "
            title="Taxi And Cab Services For Reading And North Reading Ma| Logan Airport Taxi Flat Rate "
            title1="Reading MA, United States "
            title2="Taxi and cab services from Logan airport to Reading and North Reading ma| Logan airport taxi flat rate "
            desc2="Get Logan airport taxi flat rate for taxi and cab services from Logan airport to Reading and North Reading ma. Boston Logan Airport Express Car is excited to bring the residents of Reading an airport transportation company with a difference. We pride ourselves on delivering outstanding value to our customers and providing an efficient, reliable and punctual transportation service. We service the Reading, North Reading, Billerica and more areas with a large fleet of taxi’s and corporate Reading Express Car Service and Reading taxi service that are driven by professional chauffeurs. Our aim is to be the best pick-up drop-off service between Reading and Logan Airport. "
            desc3="We go the extra mile for our customers and our large range of vehicles is sure to cater to all your transportation needs. We offer our customers no-cost extras such as extra luggage space, child seats and an efficient shuttle service running to and from the airport. Unlike some companies, we don’t overcharge for delivering this premium service. Our rates are some of the most competitive around which is why we remain our customer’s first choice when traveling to Logan Airport. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation. "
            desc4=" "
            desc5=" "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/lowell-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Lowell to Boston Logan Airport Taxi, Lowell Taxi Service To Logan Airport< "
            metadesc="Are you searching for Lowell to Boston Logan Airport Taxi? You are on the right place we are offering best fare Lowell to Boston Logan Airport Taxi services. Book your Lowell Taxi Service To Logan Airport with us today. "
            title="Lowell Taxi Service To Logan Airport By Transportation Cab Companies In Lowell Ma "
            title1="Lowell MA, United States "
            title2="Lowell Taxi service to Logan airport by transportation cab companies in Lowell Ma "
            desc2="Boston airport express car is one of the best taxi, town car and cab companies in Lowell ma providing Lowell taxi service, Lowell ma and Logan airport transportation to Lowell ma. At Boston airport express car and Taxi Service in Lowell, we understand that business professionals don't have time to waste, especially when it comes to an executive car service. That's why we'll get you there on time, every time. "
            desc3="Newly becomes a bigger corporate car service and has been providing Taxi, limo and car services to the Lowell visitor, Industrial corporate and personal market. Our customers appreciate our dedication to providing reliable, comfortable and safe transportation, as well as our commitment to treating each customer with prompt professionalism. "
            desc4="Whether you're seeking luxury car, Premier Taxi or limousine service to Boston Logan International, Bradley or TF Green Airport, a convention, an important meeting or a special event, our experienced chauffeurs know how important it is that you arrive on time. We strive to ensure prompt, reliable service in a late-model, well-maintained luxury vehicle driven by a courteous, professional chauffeur. "
            desc5="We know the Lowell and surrounding areas inside and out; we also provide transportation services within the rest of the state of Massachusetts. We're available 24 hours a day, seven days a week; no job is too large or too small for our fleet of more than 25 luxury sedans, SUVs, and minivans "
            desc6="Whether you need luxury transportation for business or pleasure, Logan Express Car Service is committed to getting you there on time, every time. For reservations or more information, call us today at (617) 499-7770 or Book Online or Setting up a corporate account. "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/bedford-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Bedford ma to/from Logan International airport taxi service and rate "
            metadesc="Get rates for taxi services from Bedford ma to Boston Logan International airport. Find best Logan taxi services with affordable rates"
            title="Bedford Ma To/From Logan International Airport Taxi Services And Rates"
            title1="Bedford MA, United States "
            title2="Bedford ma to/from Logan International airport taxi services and rates "
            desc2="Need a ride from Bedford ma to Boston Logan International airport? Find our best rates and services for a taxi, SUV, Minivan or VAN from Logan airport to Bedford ma and Bedford ma to Logan International airport. "
            desc3=" When you need a ride anywhere, Logan Airport Express Taxi & Private Car Livery Services is here for you"
            desc4="Airport travel from Bedford starting at $75.00! We take reservations 24 hours a day! Call us 617-499-7770 or Book online! Airport flights monitored 24/7! "
            desc5="Whatever your needs, the occasion, or destination, in either the Massachusetts or New Hampshire area, you'll find we have the perfect private transportation. We are the newly largest transportation company in New England, Massachusetts. We have over 25 vehicles ready, including Lincoln Town Cars, Toyota Camry, and Toyota Highlander, Mercury Marquis, 6-passenger minivans, 7-passenger SUV and more. "
            desc6="We are cheaper than a regular taxi and Rideshare Taxi for Airport transportation because we do not charge time or waiting time. Need Child Seat? Add it to a nominal price. We are best in service because we have all flexibility for your transportation need. An advantage of Logan Express car is full converged insured vehicles, largest fleet, professional driver, the best rate in the city, smart fare calculator, secure payment, punctual service child seat and extra luggage capacity. "
            desc7="Our commitment to providing you comfortable transportation to and from the airport. Trust in a punctual service that gets you to the departure lounge on time, every time, guaranteed! If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your online reservation.When you reserve your minivan or executive sedan from Andover Airport Transportation, Taxi & Private Car Livery Services, you can rest assured of your complete safety. "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/cambridge-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Budget car rental & taxi services from Cambridge ma to Logan airport "
            metadesc="Budget car rental and taxi services from Cambridge ma to Logan International airport and Logan airport to Cambridge ma "
            title="Budget Car Rental And Taxi Services From Cambridge Ma To Logan Airport "
            title1="Cambridge MA, United States "
            title2="Budget car rental and taxi services from Cambridge ma to Logan airport "
            desc2="Need car rental or taxi services from Cambridge ma to Logan airport? We have top car rental services for Logan International airport from Cambridge ma. Our most advantageous taxi services have cheaper rates than any other in the city having a better quality of services and punctuality. "
            desc3="If you’re looking to travel to Logan Airport from Cambridge, give the friendly team at Logan Express Car a call for a professional and reliable service. We are proud to be Cambridge best airport transportation service. We deliver an outstanding and punctual service that is offered at the best price, guaranteed! After you have traveled with us, you’ll understand why we are our customer’s first choice when they rely on someone to get them to their destination on time and on budget."
            desc4="Whether you’re traveling with friends and family or a business partner, our range of taxis and corporate cars are sure to satisfy all your requirements. We want to make your journey as comfortable as possible and we offer complimentary extras such as additional luggage space, child seats, and an efficient shuttle service. Your safety and well-being is our priority and all our chauffeurs are experienced drivers with many years in the transit profession. At Logan Express Car, we pride ourselves on delivering exceptional customer service at prices that are guaranteed to be the best in Newton. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation. "
            desc5=" "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/natick-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Town car and taxi services from Natick to Logan airport"
            metadesc="Hire town car or taxi services for Natick to Logan International airport. We provide best Natick taxi services."
            title="Town Car And Taxi Services From Natick To Logan Airport | Natick Taxi Services"
            title1="Natick MA, United States "
            title2="Town car and taxi services from Natick to Logan airport | Natick taxi services"
            desc2="We have town car and taxi services from Natick to Logan International airport. We demand our taxi services as the best one in Natick. Please call us to get best rates for you for the taxi, town car, SUV or Minivan services in Natick and Logan airport and find our service that will be more comfortable to you."
            desc3="Boston Logan Airport Express Car is delighted to serve the residents of Natick with a professional, customer-driven transportation service. We offer reliable transport between Natick and Logan Airport, all at the best prices guaranteed for such a high-quality service. We are positive our range of taxis and corporate cars will cater to all your requirements, ensuring that your ride with us is stress-free and enjoyable. Our shuttle service to Logan Airport provides a convenient and comfortable means of transportation to and from the airport. No matter whether your flight is in the morning, evening, middle of the night or rush hour, trust in us to get you to the airport punctually."
            desc4="After you have traveled with us, you’ll understand why we are Boston’s number one choice when it comes to being picked-up or dropped-off at Logan Airport. We always provide our customers with exceptional value through a cost-effective service that gets you to your destination promptly, safely while ensuring a relaxing journey. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation."
            desc5=" "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/marlborough-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Taxi service from Logan airport to Marlborough ma Marlborough services"
            metadesc="Affordable taxi services and town car services from Logan airport to Marlborough ma. Get your best taxi services in Marlborough ma."
            title="Taxi Services From Logan Airport To Marlborough Ma | Marlborough Taxi Services"
            title1="Marlborough MA, United States "
            title2="Taxi services from Logan airport to Marlborough ma | Marlborough taxi services"
            desc2="We have best and affordable taxi services in all over Marlborough city. We provide best and cheap taxi and town car services from Logan airport to Marlborough, ma."
            desc3="At Logan Airport Express Car & Taxi Service in Marlborough, we understand that business professionals don't have time to waste, especially when it comes to an executive car service. That's why we'll get you there on time, every time"
            desc4="Newly becomes a bigger corporate car service and has been providing Taxi, limo and car services to the Marlborough corporate and personal market. Our customers appreciate our dedication to providing reliable, comfortable and safe transportation, as well as our commitment to treating each customer with prompt professionalism."
            desc5="Whether you're seeking luxury car, Premier Taxi or limousine service to Boston Logan International, Bradley or TF Green Airport, a convention, an important meeting or a special event, our experienced chauffeurs know how important it is that you arrive on time. We strive to ensure prompt, reliable service in a late-model, well-maintained luxury vehicle driven by a courteous, professional chauffeur."
            desc6="We know the Town of Marlborough and surrounding areas inside and out; we also provide transportation services within the rest of the state of Massachusetts. We're available 24 hours a day, seven days a week; no job is too large or too small for our fleet of more than 25 luxury sedans, SUVs, and minivans."
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/burlington-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Taxi service from Lowell and Burlington ma to airport"
            metadesc="We provide best taxi services in Burlington. Ride from Burlington or Lowell ma to Boston Logan International airport"
            title="Taxi Service From Lowell And Burlington Ma To Airport | Burlington Taxi Services"
            title1="Burlington MA, United States"
            title2="Taxi service from Lowell and Burlington ma to Logan airport | Burlington taxi services"
            desc2="We have best taxi services with affordable fares in Lowell and Burlington ma. Get your ride by car, SUV, VAN, Minivan or town car from Lowell or Burlington ma to Boston Logan International airport."
            desc3="At Boston Logan Airport Express, Burlington Car and Taxi Service and we understand that business professionals don't have time to waste, especially when it comes to an executive car service. That's why we'll get you there on time, every time."
            desc4="Newly became a bigger corporate car service and has been providing Taxi, limo and car services to the Burlington corporate and personal market. Our customers appreciate our dedication to providing reliable, comfortable and safe transportation, as well as our commitment to treating each customer with prompt professionalism."
            desc5="Whether you're seeking luxury car, Premier Taxi or limousine service to Boston Logan International, Bradley or TF Green Airport, a convention, an important meeting or a special event, our experienced chauffeurs know how important it is that you arrive on time. We strive to ensure prompt, reliable service in a late-model, well-maintained luxury vehicle driven by a courteous, professional chauffeur."
            desc6="We know the Town of Burlington and surrounding areas inside and out; we also provide transportation services within the rest of the state of Massachusetts. We're available 24 hours a day, seven days a week; no job is too large or too small for our fleet of more than 25 luxury sedans, SUVs, and minivans. "
            desc7="Whether you need luxury transportation for business or pleasure, Logan Express Car Service is committed to getting you there on time, every time. For reservations or more information, call us today at (617) 499-7770 or Book Online or Setting up a corporate account. "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/waltham-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Waltham MA To Logan Airport Taxi | Boston Airport Express Car"
            metadesc="Are you searching for Waltham MA To Logan Airport Taxi? We offer the best taxi services from Waltham MA to Boston Logan International airport at an affordable price. To book Waltham MA To Logan Airport Taxi call us now (617)499-7770."
            title="Waltham Ma To Boston Logan Taxi Services | Boston Car Rental Company"
            title1="Waltham MA, United States "
            title2="Waltham ma to Boston Logan taxi services | Waltham car rental company  "
            desc2="Our taxi services in Waltham ma are renowned for its services and cheap fares. When you need taxi services from Waltham ma to Boston Logan, we can provide you best prices and services in the city. Please call us and get your best price to reserve."
            desc3="Boston Logan Airport Express Car is now extending their airport transportation services to the Waltham area. We pride ourselves on offering the best taxi and corporate car service between Waltham and Logan Airport. We are a reliable transportation company that you can trust to get you to your destination punctually. Our customers have complete faith in us and repeatedly engage our services for the professionalism we bring and the outstanding value we offer. Our rates are the best price guaranteed for a taxi company of this caliber."
            desc4="If you want a comfortable and trouble-free journey, choose Logan Express Car to drop you off at your destination without harm and on time. When traveling with our chauffeurs, you’ll know and feel you’re in safe hands, letting you relax and enjoy your journey. We offer a range of transport options to suit all your needs from taxis and cars to our airport shuttle service. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation."
            desc5=" "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/revere-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Revere taxi services | Revere to Boston Logan airport taxi services"
            metadesc="Boston airport express car provide taxi services in Revere and town car services from Revere to Logan International airport."
            title="Boston airport express car provide taxi services in Revere and town car services from Revere to Logan International airport."
            title1="Revere MA, United States"
            title2="Revere taxi services | Revere Logan airport taxi services"
            desc2="Boston airport express car provides taxi services in Revere and town car services from Revere to Logan International airport. We always ensure best transport services with cheap price than any other taxi company in Revere."
            desc3="We are offering the largest fleet of Airport taxis, car hire, minivans, midsize SUV’s, Full-size SUV’s service in Revere its neighborhoods, designed to meet all the needs of our customers. Our range of taxis, corporate cars, minivans and SUV’s ensure that your ride with us is hassle-free and enjoyable. We also have a large number of Toyota Minivans, Highlander for our customers who need extra space. In addition, we continue to add Prius hybrid vehicles to our fleet in an effort to reduce our carbon footprint and do our part to help the environment. Our commitment to providing you comfortable transportation to and from the airport. Trust in a punctual service that gets you to the departure lounge on time, every time, guaranteed!"
            desc4="We pride ourselves on being the best pick-up and drop-off service between Revere and Logan Airport. Our aim is to provide exceptional value to our customers by offering a cost-effective and flat rates car service that gets you to your destination promptly, safely while ensuring a relaxing journey. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your online reservation."
            desc5=" "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/concord-to-boston-logan-taxi">
          <ServiceDetails
            metatitle="Concord MA To Logan Airport Taxi | Concord Taxi Services"
            metadesc="Do you need Concord MA To Logan Airport Taxi services? We provide best and affordable taxi services in Concord city and Concord ma to Logan airport Taxi. Call now at (617)499-7770 and book your Concord MA To Logan Airport Taxi."
            title="Concord Ma To Logan Airport Taxi And Town Car Services | Concord Taxi Services"
            title1="Concord MA, United States"
            title2="Concord ma to Logan airport taxi and town car services | Concord taxi services  "
            desc2="We have taxi services in all over Concord city with affordable price. Our taxi and town car services from Concord ma to Logan airport have an affordable price so that you don’t need to search for another way for your ride and you can get the price instantly from our reservation system."
            desc3="Logan Airport Express Car is proud to serve Concord city with an efficient and customer focused transportation service. If you are looking to travel to Logan Airport from Concord, then contact Logan Express Car for a reliable and professional service. Our range of taxis, corporate cars, minivans and SUV’s ensure that your ride with us is hassle-free and enjoyable. Our shuttle service to Logan Airport provides a convenient and comfortable means of transportation to and from the airport. Trust in a punctual service that gets you to the departure lounge on time, every time, guaranteed!"
            desc4="We pride ourselves on being the best pick-up and drop-off service between Concord and Logan Airport. Our aim is to provide exceptional value to our customers by offering a cost-effective service that gets you to your destination promptly, safely while ensuring a relaxing journey. If you would like to arrange a booking with us, please enter your details into the form for an instant quote and to proceed with your reservation."
            desc5="We are the largest Cost Effective Service provider include Concord taxi, Concord Car Service, Logan Airport Taxi Service. So, Book your car today. "
            boldtitle1="GUARANTEED RELIABLE SERVICE -  BOOK ONLINE OR CALL US TODAY! "
          />
        </Route>
        <Route path="/reserve-a-ride">
          <ServiceDetails
            metatitle="Reserve A Ride For Boston Logan Airport Taxi Services"
            metadesc="Boston Logan International airport to/from Woburn ma taxi services with affordable fares. You can easily use our reservation module to get our instant services"
            title="Reserve A Ride For Boston Logan Airport Taxi Services"
            desc1="You can easily use our reservation module to get instant Boston Logan airport taxi services rates and can book for a ride. You can also reserve for your ride by simply calling us for any destination including Boston, Arlington, Medford, Lexington and all the cities of Massachusetts."
            title2="Reserve a taxi from Lexington ma to Logan airport"
            desc2="It has never been so easy to reserve for your ride from Lexington ma to Logan airport with simply calling us or by using reservation form."
            desc3="At www.bostonairportexpresscar.com, we offer the verity of Boston Logan Airport transfer service including Logan Airport Taxi, Boston Airport Car Service, Logan Airport Car Service, Boston Airport Taxi MA, Premium taxi for Logan Airport, Minivan and shuttle service."
            desc4="Whether you need reliable and punctual service to get to the airport on time or an important meeting, our experienced drivers can get you safely where you need to go in no time on time... guaranteed."
            desc5="Get an instant fare quote and flat-rate quote using our smart fare calculator, book your Boston airport transportation to transport or reschedule through our convenient, user-friendly online portal."
            desc6="Why go with any other car or limo transport service? Get Instant Quote and Book Your Ride Today! We also offer variety of city in Massachusetts region: Our Popular City"
            boldtitle1="JUST PAY $1 AND GET INSTANT CONFIRMATION FOR YOUR AIRPORT CAR OR TAXI RESERVATION AND IT'S EASY, HASSLE FREE & SAFE."
          />
        </Route>

        <Route path="/terms-and-conditions">
          <Terms />
        </Route>
        <Route path="/privacy-policy">
          <Privacy />
        </Route>
        <Route path="/">
          <Index />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
