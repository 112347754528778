import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import Table from "react-bootstrap/Table";
import FooterComp from "../components/FooterComp";
const FlatRate = ({ Data = null, Data2 = null }) => {
  const defaultData = [
    {
      city: "Allston, MA",
      rate: "$70.00",
    },

    {
      city: "Newton, MA	",
      rate: "$75.00",
    },
    {
      city: "Cambridge, MA	",
      rate: "$70.00",
    },
    {
      city: "Waterton, MA	",
      rate: "$80.00",
    },
    {
      city: "Waltham, MA	",
      rate: "$85.00",
    },
    {
      city: "Belmont, MA	",
      rate: "$75.00",
    },
    {
      city: "Arlington, MA",
      rate: "	$75.00",
    },
    {
      city: "Lexington, MA	",
      rate: "$90.00",
    },
    {
      city: "Brookline, MA",
      rate: "	$75.00",
    },
  ];
  const defaultData2 = [
    {
      city: "Burlington, MA",
      rate: "	$80.00",
    },
    {
      city: " Woburn, MA",
      rate: "	$80.00",
    },
    {
      city: "Malden, MA",
      rate: "	$75.00",
    },
    {
      city: "Medford, MA",
      rate: "	$70.00",
    },
    {
      city: "Somerlville, MA",
      rate: "	70.00",
    },
    {
      city: "Weston, MA",
      rate: "	$90.00",
    },
    {
      city: "Bedford, MA",
      rate: "	$95.00",
    },
    {
      city: "Brighton, MA",
      rate: "	$70.00",
    },
  ];

  if (!Data) Data = defaultData;
  if (!Data2) Data2 = defaultData2;
  return (
    <>
      <Nav
        title="Boston Airport Taxi Rates, Logan Airport Taxi Flat Rate"
        desc=" Get the best discount on Logan airport taxi flat rate, Boston airport taxi rates on distance and Boston Logan taxi flat rate. Call us at (617)499-7770 for more details."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap pickup__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   md__title">
                <strong>Logan Airport Taxi Flat Rate</strong>
              </h4>
              <p>
                Boston Airport Express Car has best Logan airport taxi flat rate
                in the city. We are proud to provide best taxi cab transport
                service having cheap Boston Logan taxi rates. Please find below
                Boston Logan taxi flat rate but if you think our price isn’t
                cheap than any other regarding the same service, please call us
                and we will surely offer you best taxi rates for your ride.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>City/Town</th>
                    <th> Flat Rate to Logan Airport</th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((info, i) => (
                    <tr>
                      <td>{info.city}</td>
                      <td>{info.rate}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col lg="6">
            <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>City/Town</th>
                    <th> Flat Rate to Logan Airport</th>
                  </tr>
                </thead>
                <tbody>
                  {Data2.map((info, i) => (
                    <tr>
                      <td>{info.city}</td>
                      <td>{info.rate}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col lg="12">
              <h4 className="mb-4   md__title">
                <strong>
                  GUARANTEED RELIABLE SERVICE - BOOK ONLINE OR CALL US TODAY!
                </strong>
              </h4>
              <h4 className="mb-4   md__title">
                <strong>
                  WE HAVE BEST BOSTON LOGAN TAXI RATES AND BOSTON LOGAN TAXI
                  FLAT RATE
                </strong>
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterComp />
    </>
  );
};
export default FlatRate;
