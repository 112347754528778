import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";
const About = () => {
  return (
    <>
      <Nav
        title="About us Boston airport express car service Budget rent car company"
        desc="Find Budget rent a car service for Boston Logan airport and get cheap airport taxi cab services for your ride in Boston and Logan airport. Our Logan Airport Taxi services are designed to make sure you arrive on time to your destination safely and in comfort."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            About Us
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   ">
                <strong> About Boston Airport Express Car Services</strong>
              </h4>
              <h4 className="mb-4  md__title ">Budget rent a car company for Logan airport</h4>
              <p>
              Boston Airport Express Car Service Provides Boston Airport Car, Airport Taxi, Boston taxi, Airport Car Service and Boston shuttle-minivan with cheapest fare to/from the Boston Logan International Airport. Also Boston Airport Express Car service has the competitive Boston Airport car and taxi service fare that makes the passenger transportations as the adorable journey with no hidden cost and punctual transfer service.
              </p>
               
              <p>
              Our Boston Airport Taxi service comes with the best rates in the city, so you never have to break the bank to get to and from your accommodations when you choose our Boston Airport Car Service. We specialize in prompt and professional Logan Airport Transfer, Airport Taxi Cab Boston, Car service Boston and Boston taxi, express shuttle minivan. All of our drivers are highly trained to put your needs first. We take your safety and seriously, which is why we only hire experienced and certified former taxi drivers for our team.
              </p>
              <p>
              No more hassling for a taxi to get where you need to be, and no more waiting around for a taxi service to be there when you need them. Now all you have to do is give a call or book online. We guarantee Boston airport taxi and car service, we will be there when you need us. Planning to/from Boston Logan Airport, check our fleet of cars and let us help make your trip hassle free. Click Logan airport taxi rates to find our rates for your and if you think, our price is more than other, we guarantee to offer you best price in the city.
              </p>
              <p><strong>Budget rent a car company for Boston Logan international Airport</strong></p>
              <p><strong>Best taxi cab and town car services for Boston Logan International Airport</strong></p>
              <h4 className="mb-4  md__title ">Best Boston Logan International airport taxi cab services</h4>
            
                
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-5">
              <h4 className="mb-4   md__title ">BOSTON AIRPORT EXPRESS CARS: ADVANTAGES</h4>
              <h4 className="mb-4  md__title ">Guranteed Airport Transportation Services -Book Today</h4>
              <ListGroup className="  diamond-list">
                <ListGroup.Item>
BEST RATE IN THE CITY
                </ListGroup.Item>
                <ListGroup.Item>
                  SMART FARE CALCULATOR
                </ListGroup.Item>
                <ListGroup.Item>
                SPACIOUS MINI VANS
                </ListGroup.Item>
                <ListGroup.Item>
                  CHILD SEATS
                </ListGroup.Item>
                <ListGroup.Item>
                PUNCTUAL SERVICE
                </ListGroup.Item>
                <ListGroup.Item>
                EXTRA LUGGAGE
                </ListGroup.Item>
                <ListGroup.Item>
                SECURE PAYMENTS
                </ListGroup.Item>
                
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};
export default About;
