import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";
const PickupLocation = ({ Data = null }) => {
  const defaultData = [
    {
      title: "DELTA/SOUTHWEST",
      description:
        "Go outside the baggage claim, cross over two traffic lanes and walk to the left to Meet your driver at the designated limo stand.",
      terminal: "TERMINAL-A",
    },
    {
      title: "AIR CANADA/US AIRWAYS",
      description:
        "Go ground floor parking garage, go outside the door and walk & cross the ground street to the parking lot. Follow the Limo or Taxi Stand Sign. The driver will be waiting for you at Limo Stand.",
      terminal: "TERMINAL-B1",
    },
    {
      title: "UNITED/AMERICA",
      description:
        "Go ground floor parking garage, go outside the door and walk to parking lot limo pickup in Arrival Level. The driver will be waiting for you at the designated Limo Stand.",
      terminal: "TERMINAL-B2",
    },
    {
      title: "JETBLUE/EMIRATES (DEPARTURE ONLY)",
      description:
        "Please proceed to baggage claim & Take the Elevator or Escalate to go upper Level (Departure Level) Follow Exit to Limo & Cross the Ground Street meet your driver at Limo Stand.",
      terminal: "TERMINAL-C",
    },
    {
      title: "ALL INTERNATIONAL AIRLINES",
      description:
        "Proceed to the terminal exit in Arrival Level. Go outside the door, cross over two traffic lanes and walk to the left. The driver will be waiting for you at the limo stand.",
      terminal: "TERMINAL-E",
    },
    {
      title: "AMTRAK",
      description:
        "Take the exit to Street (Atlantic Ave) or (Summer Street), Please Text or call your driver the exit you want to come out. The driver will be waiting on the side.",
      terminal: "SOUTH STATION",
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <>
      <Nav
        title="Boston Logan International airport taxi & car rental pickup location"
        desc="Find the pickup location for Boston Logan International airport taxi and car services. Calculate fares instantly and pay with credit card."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap pickup__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   md__title">
                <strong>
                  Boston Logan International Airport Taxi And Car Pickup
                  Location Having Affordable Fares
                </strong>
              </h4>
              <p>
                Find the pickup location for Boston Logan International airport
                taxi and car services. Calculate fares instantly and pay with
                credit card.
              </p>

              <h4 className="mb-4  small__title ">
                WHERE TO MEET YOUR DRIVER AT LOGAN AIRPORT
              </h4>
              <p>
                <strong>
                  At each terminal, there is a "Limo Stand" designated area by
                  Mass port authority.
                </strong>
              </p>
            </Col>
          </Row>
          <Row>
            {Data.map((info, i) => (
              <Col lg="6" className="terminal__info" key={i}>
                <h3>{info.terminal}</h3>
                <h4>{info.title}</h4>
                <p>{info.description}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <FooterComp />
    </>
  );
};
export default PickupLocation;
